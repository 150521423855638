<template>
  <div class="container mt-3">
    <div class="row">
      <div class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2 mt-4">
        <form @submit.prevent="saveService" validate v-if="!loading">
          <div class="card theme-card p-5 border-lg-0">
            <h1 class="mb-5">
              {{ $route.params.id ? "Edit" : "Add" }}
              {{ $route.params.parentServiceId ? "Sub" : "" }} Service
            </h1>
            <input-control
              type="horizontal"
              v-model="service.name"
              required
              :disabled="!isBillable"
              labelClass="col-md-3 col-lg-2"
              >Name</input-control
            >
            <input-control
              type="horizontal"
              v-if="isBillable"
              v-model="service.dashboard_name"
              labelClass="col-md-3 col-lg-2"
              >Dashboard Name</input-control
            >

            <div
              class="form-group row"
              v-if="!$route.params.parentServiceId && !$route.params.id"
            >
              <label class="col-form-label col-12 col-md-3 col-lg-2"
                >Billable?</label
              >
              <div class="col">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input pointer-cursor"
                    id="service-billable-id"
                    v-model="isServiceBillable"
                  />
                  <label
                    class="custom-control-label pointer-cursor"
                    for="service-billable-id"
                    >{{ isServiceBillable ? "Yes" : "No" }}</label
                  >
                </div>
              </div>
            </div>

            <input-control
              type="horizontal"
              v-model="service.code"
              v-if="isBillable && isServiceBillable"
              labelClass="col-md-3 col-lg-2"
              >Code</input-control
            >

            <select-control
              v-model="service.prov"
              required
              labelClass="col-12 col-md-3 col-lg-2"
              :options="provOptions"
              v-if="isBillable"
              >Province</select-control
            >

            <div class="form-group row" v-if="isBillable && isServiceBillable">
              <label for class="col-md-3 col-lg-2 col-form-label"
                >Profession</label
              >
              <div class="col">
                <select
                  v-model="service.profession_id"
                  class="form-control"
                  required
                >
                  <option
                    v-for="p in professions.data"
                    :key="p.id"
                    :value="p.id"
                  >
                    {{ p.name }}
                  </option>
                </select>
              </div>
            </div>

            <input-control
              control="number"
              type="horizontal"
              step="0.01"
              required
              v-if="isBillable && isServiceBillable"
              v-model="service.rate"
              min="0"
              labelClass="col-md-3 col-lg-2"
              >Rate</input-control
            >
            <select-control
              v-model="service.unit"
              required
              :options="units"
              labelClass="col-12 col-md-3 col-lg-2"
              v-if="isBillable && isServiceBillable"
              @input="checkSelectedUnit"
              >Unit</select-control
            >
            <div
              class="form-group row d-flex align-items-center"
              v-if="isBillable && isServiceBillable"
            >
              <label class="col-3 col-lg-2 col-form-label">Taxable</label>
              <div
                class="custom-control custom-radio mx-3"
                v-for="option in taxableOptions"
                :key="option.label"
              >
                <input
                  type="radio"
                  v-bind:id="option.label"
                  v-model="service.taxable"
                  v-bind:value="option.value"
                  :disabled="
                    service.unit == 'KM' || service.is_default == 'true'
                  "
                  name="taxable"
                  class="custom-control-input"
                />
                <label class="custom-control-label" v-bind:for="option.label">{{
                  option.label
                }}</label>
              </div>
            </div>

            <select-control
              type="horizontal"
              :options="yesNoOptions"
              required
              v-if="isBillable && isServiceBillable"
              labelClass="col-12 col-md-3 col-lg-2"
              v-model="service.catastrophic"
              >Catastrophic</select-control
            >

            <select-control
              type="horizontal"
              :options="yesNoOptions"
              required
              labelClass="col-12 col-md-3 col-lg-2"
              v-if="isBillable && isServiceBillable && false"
              v-model="service.is_default"
              @input="checkSelectedUnit"
              >Default Service</select-control
            >
            <input-control
              type="horizontal"
              v-model="service.description"
              control="textarea"
              maxLength="500"
              labelClass="col-md-3 col-lg-2"
              >Description</input-control
            >

            <select-control
              type="horizontal"
              :options="statusOptions"
              required
              labelClass="col-12 col-md-3 col-lg-2"
              v-if="isBillable"
              v-model="service.status"
              >Status</select-control
            >

            <div class="form-group row" v-if="!$route.params.parentServiceId">
              <label class="col-auto col-md-3 col-lg-2 col-form-label"
                >Calendar Box Color</label
              >
              <div class="col">
                <div
                  class="color-picker-value rounded"
                  :style="{ backgroundColor: `rgba(${service.color})` }"
                  @click="openColorPickerModal"
                ></div>
              </div>
            </div>

            <save
              classes="mt-3 col-md-3 col-lg-2 save-button btn btn-theme"
              :saving="saving"
              >{{ this.$route.params.id ? "Update" : "Save" }}</save
            >
          </div>

          <SubServices
            v-if="
              $route.params.id &&
              !$route.params.parentServiceId &&
              isBillable &&
              isServiceBillable
            "
          ></SubServices>
        </form>
        <alert v-if="loading" class="light-shadow" />
      </div>
    </div>
    <modal
      class="update-service-color-modal"
      name="update-service-color-modal"
      transition="pop-out"
      :height="450"
      :width="350"
    >
      <div class="position-relative">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button
            class="btn p-0 pr-3 pt-3 border-0"
            @click="closeColorPickerModal"
          >
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="card-body">
          <ColorPicker
            :color="selectedColor"
            :onStartChange="(color) => onColorChange(color)"
            :onChange="(color) => onColorChange(color)"
            :onEndChange="(color) => onColorChange(color)"
          />
          <div
            class="
              col-12
              p-0
              d-flex
              justify-content-center
              mt-3
              border-top
              pt-4
            "
          >
            <save @click="updateServiceColor">Confirm Service Color</save>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";
import { default as SubServices } from "./SubServices";
import { ColorPicker } from "vue-color-gradient-picker";
import "vue-color-gradient-picker/dist/index.css";

export default {
  components: {
    SubServices,
    ColorPicker,
  },
  computed: {
    ...mapState(["professions"]),
    ...mapState({
      saving: (state) => state.services.isSaving,
    }),
    isBillable() {
      if (
        this.service.profession_id ||
        !this.$route.params.id ||
        this.$route.params.parentServiceId ||
        this.service.name != "Non-billable Service"
      ) {
        return true;
      }
      return false;
    },
    units() {
      if (this.$route.params.parentServiceId) {
        return [
          { value: "HR", label: "Hourly" },
          { value: "KM", label: "Kilometers" },
        ];
      }
      return [
        { value: "HR", label: "Hourly" },
        { value: "FT", label: "Flat Rate" },
      ];
    },
  },
  name: "create.service",
  data() {
    return {
      isServiceBillable: true,
      provOptions: [
        { label: "Alberta", value: "AB" },
        { label: "British Columbia", value: "BC" },
        { label: "Manitoba", value: "MB" },
        { label: "Northwest Territories", value: "NT" },
        { label: "Nunavut", value: "NU" },
        { label: "Quebec", value: "QC" },
        { label: "Saskatchewan", value: "SK" },
        { label: "Yukon", value: "YT" },
        { label: "Ontario", value: "ON" },
        { label: "New Brunswick", value: "NB" },
        { label: "Newfoundland and Labrador", value: "NL" },
        { label: "Nova Scotia", value: "NS" },
        { label: "Prince Edward Island", value: "PE" },
      ],
      cleave: {
        rate: { numericOnly: true, blocks: [10] },
      },
      service: {
        name: null,
        dashboard_name: null,
        rate: 0,
        prov: null,
        unit: "HR",
        taxable: false,
        profession_id: "",
        catastrophic: false,
        is_default: false,
        description: "",
        color: "204, 204, 204, 1",
        code: "",
        status: "ACTIVE",
      },
      taxableOptions: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      yesNoOptions: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      statusOptions: [
        { label: "Active", value: "ACTIVE" },
        { label: "Inactive", value: "INACTIVE" },
      ],
      selectedColor: {
        red: 204,
        green: 204,
        blue: 204,
        alpha: 1,
      },
      loading: false,
    };
  },
  async mounted() {
    if (!this.professions || !(this.professions.data || []).length) {
      this.getProfessions();
    }
    if (this.$route.params.id != undefined) {
      this.loading = true;
      const res = await this.$http.get(`services/${this.$route.params.id}`);
      this.service = res.data.data;
      if (this.service.unit == "FLAT") {
        // Support old logic
        this.service.unit = "FT";
      }
      if (
        this.service.code == "NON-BILLABLE" ||
        this.service.name == "non-billable service"
      ) {
        this.isServiceBillable = false;
      } else {
        this.isServiceBillable = true;
      }
      this.checkSelectedUnit();
      const serviceColors = (this.service.color || "").split(",");
      this.selectedColor = {
        red: parseInt(serviceColors[0] || 204),
        green: parseInt(serviceColors[1] || 204),
        blue: parseInt(serviceColors[2] || 204),
        alpha: parseFloat(serviceColors[3] || 1),
      };
      this.loading = false;
    }
  },
  methods: {
    ...mapActions({
      getProfessions: "professions/all",
      createService: "services/create",
      updateService: "services/update",
    }),
    clearService: function () {
      this.service = {
        name: null,
        dashboard_name: null,
        rate: 0,
        prov: null,
        unit: "HR",
        taxable: false,
        profession_id: "",
        catastrophic: false,
        is_default: false,
        description: "",
        color: "204, 204, 204, 1",
        code: "",
        status: "ACTIVE",
      };
    },
    saveService: function () {
      if (this.$route.params.id != undefined) {
        // update service
        if (this.service.code == "NON-BILLABLE" && this.isServiceBillable) {
          return Swal.fire({
            title: "Warning",
            text: "Invalid service code, please use another code.",
            icon: "warning",
          });
        }
        this.updateService({
          ...this.service,
          id: this.$route.params.id,
        }).then((result) => {
          if (result) {
            Swal.fire({
              title: `${
                this.$route.params.parentServiceId ? "Sub" : ""
              } Service Updated Successfully`,
              text: "",
              icon: "success",
            });
            if (this.$route.params.parentServiceId) {
              this.$router.push(
                `/admin/services/${this.$route.params.parentServiceId}`
              );
            } else {
              this.$router.push(`/admin/services`);
            }
          }
        });
      } else if (this.$route.params.parentServiceId != undefined) {
        // create sub service
        if (this.service.code == "NON-BILLABLE") {
          return Swal.fire({
            title: "Warning",
            text: "Invalid Service Code, please use another code.",
            icon: "warning",
          });
        }
        this.createService({
          ...this.service,
          parent_service_id: this.$route.params.parentServiceId,
        }).then((result) => {
          if (result) {
            Swal.fire({
              title: "Sub Service Created Successfully",
              text: "",
              icon: "success",
            });
            this.$router.push(
              `/admin/services/${this.$route.params.parentServiceId}`
            );
          }
        });
      } else {
        // create service
        if (!this.isServiceBillable) {
          this.service.code = "NON-BILLABLE";
          this.service.is_default = false;
          this.service.catastrophic = false;
          this.service.profession_id = "";
          this.service.taxable = false;
          this.service.rate = 0;
          this.service.unit = "HR";
        } else if (this.service.code == "NON-BILLABLE") {
          return Swal.fire({
            title: "Invalid Service Code",
            text: "(NON-BILLABLE) code is served for non billable services, please use another one.",
            icon: "warning",
          });
        }
        this.createService(this.service).then((result) => {
          if (result) {
            if (!this.isServiceBillable) {
              return this.$router.push("/admin/services");
            }
            let vm = this;
            vm.$swal({
              title: "Service Created Successfully",
              text: "Do you want to add sub service for it?",
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "Yes, add sub service",
              cancelButtonText: "No, thanks",
            }).then((decide) => {
              if (decide.value) {
                this.clearService();
                this.$router.push(
                  `/admin/services/${result.id}/create-sub-services`
                );
              } else {
                this.$router.push("/admin/services");
              }
            });
          }
        });
      }
    },
    onColorChange: function (color) {
      this.selectedColor = { ...color };
    },
    openColorPickerModal: function () {
      this.lastPosition = document.documentElement.scrollTop;
      window.scrollTo(0, 0);
      const serviceColors = (this.service.color || "").split(",");
      this.selectedColor = {
        red: parseInt(serviceColors[0] || 204),
        green: parseInt(serviceColors[1] || 204),
        blue: parseInt(serviceColors[2] || 204),
        alpha: parseFloat(serviceColors[3] || 1),
      };
      this.$modal.show("update-service-color-modal");
    },
    closeColorPickerModal: function () {
      this.$modal.hide("update-service-color-modal");
      window.scrollTo(0, this.lastPosition);
    },
    updateServiceColor: function () {
      this.service.color = `${this.selectedColor.red}, ${this.selectedColor.green}, ${this.selectedColor.blue}, ${this.selectedColor.alpha}`;
      this.closeColorPickerModal();
    },
    checkSelectedUnit: function () {
      if (this.service.unit == "KM" || this.service.is_default) {
        this.service.taxable = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.color-picker-value {
  box-shadow: 0px 0px 6px 0px #062157;
  width: 40px;
  height: 30px;
  cursor: pointer;
}
</style>
