<template>
  <div class="mt-4 sub-service-container">
    <div class="row">
      <div class="col-12 col-md-8">
        <h1>Sub Services</h1>
      </div>
      <div class="col text-right">
        <router-link
          :to="`/admin/services/${$route.params.id}/create-sub-services`"
          class="btn btn-outline-blue btn-sm mb-3 mb-lg-0"
        >
          <icon type="plus" /> Add Sub Service
        </router-link>
      </div>
    </div>
    <alert v-if="loading" class="light-shadow" />
    <div class="p-3 card light-shadow" v-if="!loading && services.length == 0">
      No Sub Services Found.
    </div>
    <div
      class="
        card
        light-shadow
        mobile-tablet-no-card
        table-container
        border-lg-0
        overflow-auto
      "
      v-if="services.length > 0"
    >
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Prov</th>
            <th>Rate</th>
            <th>Status</th>
            <th class="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="s in services" :key="s.id">
            <td><b class="mobile-tablet-only mr-2">Name:</b>{{ s.name }}</td>
            <td>
              <b class="mobile-tablet-only mr-2">Prov:</b>{{ getProv(s.prov) }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Rate:</b>${{ s.rate }}/{{
                s.unit
              }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Status:</b
              ><span
                class="font-weight-bold"
                :class="s.status == 'INACTIVE' ? 'text-danger' : 'text-success'"
                >{{ s.status }}</span
              >
              <i
                class="fa fa-lg fa-info-circle ml-2"
                v-if="s.active_by"
                v-tooltip.top="activationInfo(s)"
              ></i>
            </td>
            <td class="text-center td-actions">
              <router-link
                :to="`/admin/services/${s.parent_service_id}/${s.id}/edit-sub-services`"
                class="mr-3 btn btn-sm btn-black"
              >
                <b class="mobile-tablet-only mr-2">Edit</b><icon type="edit" />
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import * as dayjs from "dayjs";

export default {
  name: "services.sub",
  data() {
    return {
      loading: false,
      services: [],
      provOptions: [
        { label: "Alberta", value: "AB" },
        { label: "British Columbia", value: "BC" },
        { label: "Manitoba", value: "MB" },
        { label: "Northwest Territories", value: "NT" },
        { label: "Nunavut", value: "NU" },
        { label: "Quebec", value: "QC" },
        { label: "Saskatchewan", value: "SK" },
        { label: "Yukon", value: "YT" },
        { label: "Ontario", value: "ON" },
        { label: "New Brunswick", value: "NB" },
        { label: "Newfoundland and Labrador", value: "NL" },
        { label: "Nova Scotia", value: "NS" },
        { label: "Prince Edward Island", value: "PE" },
      ],
    };
  },
  mounted() {
    let vm = this;
    vm.loading = true;
    this.$http
      .get(`services/${this.$route.params.id}/subservices`)
      .then((res) => {
        vm.loading = false;
        vm.services = res.data.data;
      })
      .catch((err) => {
        if (!err.accessDenied) {
          Swal.fire({
            title: "Error",
            text: (err.data || {}).message || "Something went wrong...",
            icon: "error",
          });
        }
        vm.loading = false;
      });
  },

  methods: {
    getProv: function (val) {
      var relatedProv = this.provOptions.find((item) => item.value == val);
      if (relatedProv != null) {
        return relatedProv.label;
      } else {
        return "";
      }
    },
    prettyDate: function (dateToformat) {
      if (dateToformat == undefined || dateToformat == null) {
        return;
      }

      const canadaOffset = dayjs
        .tz(new Date(dateToformat), "America/Toronto")
        .utcOffset();

      return dayjs(dateToformat)
        .add(-1 * canadaOffset, "minute")
        .add(1 * dayjs(dateToformat).utcOffset(), "minute")
        .format("MMM DD, YYYY hh:mm A");
    },
    activationInfo: function (service) {
      if (service.status == "ACTIVE") {
        return `Activated by ${service.active_by} at ${this.prettyDate(
          service.active_date
        )}`;
      }
      return `Inactivated by ${service.active_by} at ${this.prettyDate(
        service.active_date
      )}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-service-container {
  @media (max-width: 1024px) {
    border-top: 1px solid #062157;
    padding-top: 12px;
  }
}
</style>
